import * as React from "react"
import { withPrefix, Link } from "gatsby"
import Seo from "../components/seo"
import Accesibilidad from "../components/accesibilidad"

let url_global = process.env.GATSBY_API_URL
const IndexPage = () => (
  <div className="h-screen w-full flex flex-wrap">
    <Accesibilidad />
    <Seo title="Bienvenidos" />

    <div className="w-full h-full imagenes_main image_main bg-cover pl-60 md:w-1/2 flex flex-col items-center justify-center">
      <div className="ubicar_imagen_pagina_principal  right-0 w-64 -top-8 pr-20 md:relative bottom-40 pr-16">
        <Link className="" to="/">
          <img
            src={url_global + "/uploads/logo_a1b429d0e0.PNG"}
            className="w-80 imagen_index"
            alt="logo"
            title="logo"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            style={{ maxWidth: "22vw" }}
          />
        </Link>
      </div>

      <div className="relative ubicar_texto_pagina_inicio">
        <h1 className="text-main bg-green-hover text-white text-poppins-extra_bold font-black text-5xl	width_text_main">
          Nuevo
        </h1>

        <h1 className="text-main bg-green-hover text-white text-poppins-extra_bold font-black text-5xl	width_text_two_main">
          Sitio web
        </h1>
        <h1 className="text-main text-green-default max-w-md text-poppins-extra_bold font-black	text-5xl">
          Evolucionamos para cuidar de ti
        </h1>
      </div>

      <div className="ubicar_imagen_icono_divisor rotacion_image_main relative left-12 bottom-48">
        <img
          src={url_global + "/uploads/bt_abajo_blanco_012289d38f.png"}
          className="tamano_icono_divisor"
          title="bt_abajo_blanco"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="bt_abajo_blanco"
          style={{ width: "103px" }}
        />
      </div>
    </div>

    <div style={{ display: "none" }} className="ubicar_enunciado">
      <p className="color-texto-parrafo text-poppins-bold">Para continuar,</p>
      <p className="color-texto-parrafo text-poppins-bold">
        selecciona tu tipo de usuario:
      </p>
    </div>

    <div
      style={{ backgroundColor: "white" }}
      className="ubicar_perfilador_rol md:pr-40 w-1/2 flex flex-col items-center justify-center h-full"
    >
      <a href={"/personas"}>
        <div className="main-menu-item shadow-md p-4 my-3	max-w-sm transform hover:-translate-y-1 hover:scale-110 transition duration-500">
          <h1 className="cambio_color text-green-light text-3xl text-poppins-extra_bold">
            Personas
          </h1>
          <p className="mitexto_color text-sm my-0 text-poppins-regular color-texto-parrafo">
            Recibe la información que requieras sobre tus servicios de salud,
            afiliaciones y citas médicas.
          </p>
        </div>
      </a>

      <a href={"/empresas"}>
        <div className="main-menu-item shadow-md p-4 my-3	max-w-sm transform hover:-translate-y-1 hover:scale-110 transition duration-500">
          <h1 className="text-green-default text-3xl text-poppins-extra_bold">
            Empresas
          </h1>
          <p className="mitexto_color text-sm  my-0 text-poppins-regular color-texto-parrafo">
            Accede a todos los datos relacionados a la afiliación de tus
            empleados, así como licencias e incapacidades.
          </p>
        </div>
      </a>

      <a href={"/proveedores"}>
        <div className="main-menu-item shadow-md p-4 my-3	max-w-sm transform hover:-translate-y-1 hover:scale-110 transition duration-500">
          <h1 className="text-gray-500 text-3xl text-poppins-extra_bold">
            Proveedores
          </h1>
          <p className="mitexto_color text-sm my-0 text-poppins-regular color-texto-parrafo">
            Ingresa y encuentra, de forma fácil y segura, todo lo relacionado a
            tus beneficios y servicios como proveedor.
          </p>
        </div>
      </a>
    </div>

    <div id="fondo-aviso-cookies" className="fondo-aviso-cookies"></div>

    <footer
      id="aviso-cookies"
      className="fixed ubicar_banner_cookie aviso-cookies bottom-0 text-center lg:fixed pb-20 w-full bg-footer border-t border-gray-400"
    >
      <div className="relative alinaecion_texto_aceptacion_index top-8 ml-96 items-center flex flex-wrap container inline-grid grid-cols-2 gap-4 justify-center w-full">
        <div className="text-left mr-8 ml-0 alineacion_politica_texto">
          <p className="tamano_letra_cookies text-white text-poppins-regular">
            Para mejorar tu experiencia, utilizamos cookies.
          </p>
          <p className="tamano_letra_cookie text-white text-poppins-regular">
            Da clic en "Aceptar" si estas de acuerdo con las politicas.
            <strong>
              <a
                href={
                  "https://epsdelagente.com.co/politica-cookies-epsdelagente/"
                }
                className="underline"
              >
                Más info.
              </a>
            </strong>
          </p>
        </div>
        <div className="col-span relative flex-wrap">
          <div>
            <div className="posicion_aceptacion_index space-x-5 ubicacion_aceptacion_cookies tamano_texto_aceptar_cookies md:flex text-base text-white">
              <p className="separacion_aceptacion_cookies">
                <a
                  id="btn-aceptar-cookies"
                  className="cursor-pointer inline-block no-underline rounded-full bg-green-hover py-1 px-2 font-semibold"
                >
                  Aceptar
                </a>
              </p>
              <p className="alineacion_no_gracias">
                <a
                  id="btn-no-cookies"
                  className="cursor-pointer inline-block no-underline rounded-full hover:text-green-600 hover:bg-white py-1 px-2"
                >
                  No, gracias
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
)
export default IndexPage
